<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1Box max-w-1400">
        <img :src="importedImg1" alt="" class="imgItem1" />
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2Box max-w-1400">
        <img :src="importedImg2" alt="" class="imgItem2" />
      </div>
    </div>
    <div class="imgBox3 min-w-1400">
      <div class="img3Box max-w-1400">
        <img :src="importedImg3" alt="" class="imgItem3" />
      </div>
    </div>
    <div class="imgBox4 min-w-1400">
      <div class="img4Box max-w-1400">
        <img :src="importedImg4" alt="" class="imgItem4" />
      </div>
    </div>
    <div class="imgBox5 min-w-1400">
      <div class="img5Box max-w-1400">
        <img :src="importedImg5" alt="" class="imgItem5" />
      </div>
    </div>
    <div class="imgBox6 min-w-1400">
      <div class="img6Box max-w-1400">
        <!-- <img :src="importedImg6" alt="" class="imgItem6" /> -->
        <div class="imgWrapper">
          <img :src="imported1Img6" alt="" class="imgItem6" />
          <img :src="imported2Img6" alt="" class="imgItem6" />
          <img :src="imported3Img6" alt="" class="imgItem6" />
          <img :src="imported4Img6" alt="" class="imgItem6" />
          <img :src="imported5Img6" alt="" class="imgItem6" />
          <img :src="imported6Img6" alt="" class="imgItem6" />

          <img :src="imported1Img6" alt="" class="imgItem6" />
          <img :src="imported2Img6" alt="" class="imgItem6" />
          <img :src="imported3Img6" alt="" class="imgItem6" />
          <img :src="imported4Img6" alt="" class="imgItem6" />
          <img :src="imported5Img6" alt="" class="imgItem6" />
          <img :src="imported6Img6" alt="" class="imgItem6" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./index.css";
import importedImg1 from "@/page/th.phasitpakincompany/components/img/1_1.png";
import importedImg2 from "@/page/th.phasitpakincompany/components/img/1_2.png";
import importedImg3 from "@/page/th.phasitpakincompany/components/img/1_3.png";
import importedImg4 from "@/page/th.phasitpakincompany/components/img/1_4.png";
import importedImg5 from "@/page/th.phasitpakincompany/components/img/1_5.png";
// import importedImg6 from "@/page/th.phasitpakincompany/components/img/1_6.png";
import imported1Img6 from "@/page/th.phasitpakincompany/components/img/1_6_1.png";
import imported2Img6 from "@/page/th.phasitpakincompany/components/img/1_6_2.png";
import imported3Img6 from "@/page/th.phasitpakincompany/components/img/1_6_3.png";
import imported4Img6 from "@/page/th.phasitpakincompany/components/img/1_6_4.png";
import imported5Img6 from "@/page/th.phasitpakincompany/components/img/1_6_5.png";
import imported6Img6 from "@/page/th.phasitpakincompany/components/img/1_6_6.png";

export default {
  data() {
    return {
      importedImg1: importedImg1,
      importedImg2: importedImg2,
      importedImg3: importedImg3,
      importedImg4: importedImg4,
      importedImg5: importedImg5,
      // importedImg6: importedImg6,
      imported1Img6: imported1Img6,
      imported2Img6: imported2Img6,
      imported3Img6: imported3Img6,
      imported4Img6: imported4Img6,
      imported5Img6: imported5Img6,
      imported6Img6: imported6Img6,
      altText: "图片",
    };
  },
  created() {
    this.gotoTop();
  },
  methods: {
    gotoTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: white;
}

img {
  display: block;
}

.imgBox1,
.imgBox2,
.imgBox3,
.imgBox4,
.imgBox5,
.imgBox6,
.imgBox7 {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: "";
}

.img1Box,
.img2Box,
.img3Box,
.img4Box,
.img5Box,
.img6Box,
.img7Box {
  margin: 0;
  overflow: hidden;
  width: 100%;
  background-color: "";
}

.imgItem1,
.imgItem2,
.imgItem3,
.imgItem4,
.imgItem5,
.imgItem6,
.imgItem7 {
  width: 100%;
  margin: 0;
}

.img4Box,
.img5Box {
  padding: 0 220px;
}

.img3Box {
  margin: 100px 0;
}

.img5Box {
  margin: 100px 0;
}

/* 容器最大宽度 */
.img6Box {
  width: 100%;
  overflow: hidden;
  position: relative; /* 定位容器 */
}

/* 包裹图片的外层容器 */
.imgWrapper {
  display: flex; /* 横向排列图片 */
  animation: moveImages 30s linear infinite;
  /* 动画控制，15s 为动画周期，线性平滑过渡，永远循环 */
  height: 500px;
}

/* 每张图片的样式 */
.imgItem6 {
  width: 600px;
  height: 300px; /* 图片的高度可以根据需求调整 */
  margin-right: 30px; /* 图片之间的间距 */
  margin-top: 40px;
  /* animation: moveImages 36s linear infinite; */
}
.imgItem6:hover {
  /* box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);  */
  /* 8px的垂直偏移，15px的模糊半径，30%透明度的黑色阴影 */
}
.imgItem6:nth-child(2n) {
  margin-top: 110px;
}

/* 关键帧动画：让图片从右往左移动 */
@keyframes moveImages {
  0% {
    transform: translateX(0); /* 起始位置 */
  }
  100% {
    transform: translateX(-3570px); /* 最终位置，6张图片，每张600px，移动 6*600px */
  }
}
</style>
