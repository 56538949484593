<template>
  <div class="imgBox min-w-1400">

    <div class="imgBox2 min-w-1400" @click="toggleNewImage(2)">
      <div class="img2 max-w-1400">
        <img :src="importedImg2" alt="" class="imgItem2" />
      </div>
    </div>
    <div class="imgBox1 min-w-1400" @click="toggleNewImage(1)">
      <div class="img1 max-w-1400">
        <!-- <img :src="importedImg1" alt="" class="imgItem1" /> -->
        <ul class="msg2">
          <li>
            <span>ชื่อ</span>
            <a-input
              class="inputBox"
              v-model="formValues.email"
              placeholder="กรุณาเข้า"
            >
              <!-- <template #suffix>
                <img :src="mailIcon" />
              </template> -->
            </a-input>
          </li>
          <li>
            <span>เบอร์อีเมล์</span>
            <a-input
              class="inputBox"
              v-model="formValues.mailName"
              placeholder="กรุณาเข้า"
            >
              <!-- <template #suffix>
                <img :src="mailIcon" />
              </template> -->
            </a-input>
          </li>
          <li>
            <span>คำถามของคุณ</span>
            <a-textarea
              class="textareaBox"
              v-model="formValues.remark"
              placeholder="กรุณากรอกคำถามของคุณ"
              :auto-size="{ minRows: 3, maxRows: 3 }"
            />
          </li>
          <li>
            <div>
              <a-button
                type="primary"
                :disabled="
                  formValues.mailName === '' || formValues.remark === ''
                "
                :class="{
                  submitButton: true,
                  btnBgcolor:
                    formValues.mailName !== '' && formValues.remark !== '',
                }"
                @click="submitFn"
                :loading="isLoading"
                >ส่ง</a-button
              >
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="imgBox3 min-w-1400" @click="toggleNewImage(3)">
      <div class="img3 max-w-1400">
        <img
          :src="showNewImage3 ? AnswerImg3 : importedImg3"
          :alt="altText"
          class="imgItem3"
        />
      </div>
    </div>
    <div class="imgBox4 min-w-1400" @click="toggleNewImage(4)">
      <div class="img4 max-w-1400">
        <img
          :src="showNewImage4 ? AnswerImg4 : importedImg4"
          :alt="altText"
          class="imgItem4"
        />
      </div>
    </div>
    <div class="imgBox5 min-w-1400" @click="toggleNewImage(5)">
      <div class="img5 max-w-1400">
        <img
          :src="showNewImage5 ? AnswerImg5 : importedImg5"
          :alt="altText"
          class="imgItem5"
        />
      </div>
    </div>
    <div class="imgBox6 min-w-1400" @click="toggleNewImage(6)">
      <div class="img6 max-w-1400">
        <img
          :src="showNewImage6 ? AnswerImg6 : importedImg6"
          :alt="altText"
          class="imgItem6"
        />
      </div>
    </div>
    <div class="imgBox7 min-w-1400" @click="toggleNewImage(7)">
      <div class="img7 max-w-1400">
        <img
          :src="showNewImage7 ? AnswerImg7 : importedImg7"
          :alt="altText"
          class="imgItem7"
        />
      </div>
    </div>

    <div class="imgBox8 min-w-1400" @click="toggleNewImage(8)">
      <div class="img8 max-w-1400">
        <img
          :src="showNewImage8 ? AnswerImg8 : importedImg8"
          :alt="altText"
          class="imgItem8"
        />
      </div>
    </div>
    <div class="imgBox9 min-w-1400" @click="toggleNewImage(9)">
      <div class="img9 max-w-1400">
        <img
          :src="showNewImage9 ? AnswerImg9 : importedImg9"
          :alt="altText"
          class="imgItem9"
        />
      </div>
    </div>
    <div class="imgBox10 min-w-1400" @click="toggleNewImage(10)">
      <div class="img10 max-w-1400">
        <img
          :src="showNewImage10 ? AnswerImg10 : importedImg10"
          :alt="altText"
          class="imgItem10"
        />
      </div>
    </div>
    <div class="imgBox11 min-w-1400" @click="toggleNewImage(11)">
      <div class="img11 max-w-1400">
        <img
          :src="showNewImage11 ? AnswerImg11 : importedImg11"
          :alt="altText"
          class="imgItem11"
        />
      </div>
    </div>
    <div class="imgBox12 min-w-1400" @click="toggleNewImage(12)">
      <div class="img12 max-w-1400">
        <img
          :src="showNewImage12 ? AnswerImg12 : importedImg12"
          :alt="altText"
          class="imgItem12"
        />
      </div>
    </div>
    <div class="imgBox13 min-w-1400" @click="toggleNewImage(13)">
      <div class="img13 max-w-1400">
        <img
          :src="showNewImage13 ? AnswerImg13 : importedImg13"
          :alt="altText"
          class="imgItem13"
        />
      </div>
    </div>
    <div class="imgBox14 min-w-1400" @click="toggleNewImage(14)">
      <div class="img14 max-w-1400">
        <img
          :src="showNewImage14 ? AnswerImg14 : importedImg14"
          :alt="altText"
          class="imgItem14"
        />
      </div>
    </div>
    <!-- <div class="imgBox15 min-w-1400" @click="toggleNewImage(15)">
      <div class="img15 max-w-1400">
        <img
          :src="showNewImage15 ? AnswerImg15 : importedImg15"
          :alt="altText"
          class="imgItem15"
        />
      </div>
    </div> -->
  </div>
</template>

<script>
import "./index.css";
import { message } from "ant-design-vue";
import importedImg2 from "@/page/th.phasitpakincompany/components/img/3_1.png";
import importedImg3 from "@/page/th.phasitpakincompany/components/img/3_3.png";
import importedImg4 from "@/page/th.phasitpakincompany/components/img/3_4.png";
import importedImg5 from "@/page/th.phasitpakincompany/components/img/3_5.png";
import importedImg6 from "@/page/th.phasitpakincompany/components/img/3_6.png";
import importedImg7 from "@/page/th.phasitpakincompany/components/img/3_7.png";
import importedImg8 from "@/page/th.phasitpakincompany/components/img/3_8.png";
import importedImg9 from "@/page/th.phasitpakincompany/components/img/3_9.png";
import importedImg10 from "@/page/th.phasitpakincompany/components/img/3_10.png";
import importedImg11 from "@/page/th.phasitpakincompany/components/img/3_11.png";
import importedImg12 from "@/page/th.phasitpakincompany/components/img/3_12.png";
import importedImg13 from "@/page/th.phasitpakincompany/components/img/3_13.png";
import importedImg14 from "@/page/th.phasitpakincompany/components/img/3_14.png";
// import importedImg15 from "@/page/th.phasitpakincompany/components/img/3_15.png";

import AnswerImg3 from "@/page/th.phasitpakincompany/components/img/3_3_3.png";
import AnswerImg4 from "@/page/th.phasitpakincompany/components/img/3_4_4.png";
import AnswerImg5 from "@/page/th.phasitpakincompany/components/img/3_5_5.png";
import AnswerImg6 from "@/page/th.phasitpakincompany/components/img/3_6_6.png";
import AnswerImg7 from "@/page/th.phasitpakincompany/components/img/3_7_7.png";
import AnswerImg8 from "@/page/th.phasitpakincompany/components/img/3_8_8.png";
import AnswerImg9 from "@/page/th.phasitpakincompany/components/img/3_9_9.png";
import AnswerImg10 from "@/page/th.phasitpakincompany/components/img/3_10_10.png";
import AnswerImg11 from "@/page/th.phasitpakincompany/components/img/3_11_11.png";
import AnswerImg12 from "@/page/th.phasitpakincompany/components/img/3_12_12.png";
import AnswerImg13 from "@/page/th.phasitpakincompany/components/img/3_13_13.png";
import AnswerImg14 from "@/page/th.phasitpakincompany/components/img/3_14_14.png";
// import AnswerImg15 from "@/page/th.phasitpakincompany/components/img/3_15_15.png";
export default {
  data() {
    return {
      importedImg2: importedImg2,
      importedImg3: importedImg3,
      importedImg4: importedImg4,
      importedImg5: importedImg5,
      importedImg6: importedImg6,
      importedImg7: importedImg7,
      importedImg8: importedImg8,
      importedImg9: importedImg9,
      importedImg10: importedImg10,
      importedImg11: importedImg11,
      importedImg12: importedImg12,
      importedImg13: importedImg13,
      importedImg14: importedImg14,
      // importedImg15: importedImg15,
      AnswerImg3: AnswerImg3,
      AnswerImg4: AnswerImg4,
      AnswerImg5: AnswerImg5,
      AnswerImg6: AnswerImg6,
      AnswerImg7: AnswerImg7,
      AnswerImg8: AnswerImg8,
      AnswerImg9: AnswerImg9,
      AnswerImg10: AnswerImg10,
      AnswerImg11: AnswerImg11,
      AnswerImg12: AnswerImg12,
      AnswerImg13: AnswerImg13,
      AnswerImg14: AnswerImg14,
      // AnswerImg15: AnswerImg15,

      altText: "图片",
      showNewImage2: false,
      showNewImage3: false,
      showNewImage4: false,
      showNewImage5: false,
      showNewImage6: false,
      showNewImage7: false,
      showNewImage8: false,
      showNewImage9: false,
      showNewImage10: false,
      showNewImage11: false,
      showNewImage12: false,
      showNewImage13: false,
      showNewImage14: false,
      showNewImage15: false,
      activeImage: 0,

      formValues: {
        mailName: "",
        email: "",
        remark: "",
      },
      isLoading: false,
    };
  },
  created() {
    this.gotoTop();
  },
  methods: {
    toggleNewImage(imageIndex) {
      //  console.log("点击了");
      //   this.$set(this.showNewImage, imageIndex, !this.showNewImage[imageIndex]);
      //   this.activeImage = imageIndex;
      console.log(this.showNewImage3);
      if (imageIndex == 2) {
        this.showNewImage2 = !this.showNewImage2;
      }
      if (imageIndex == 3) {
        this.showNewImage3 = !this.showNewImage3;
      }
      if (imageIndex == 4) {
        this.showNewImage4 = !this.showNewImage4;
      }
      if (imageIndex == 5) {
        this.showNewImage5 = !this.showNewImage5;
      }
      if (imageIndex == 6) {
        this.showNewImage6 = !this.showNewImage6;
      }
      if (imageIndex == 7) {
        this.showNewImage7 = !this.showNewImage7;
      }
      if (imageIndex == 8) {
        this.showNewImage8 = !this.showNewImage8;
      }
      if (imageIndex == 9) {
        this.showNewImage9 = !this.showNewImage9;
      }
      if (imageIndex == 10) {
        this.showNewImage10 = !this.showNewImage10;
      }
      if (imageIndex == 11) {
        this.showNewImage11 = !this.showNewImage11;
      }
      if (imageIndex == 12) {
        this.showNewImage12 = !this.showNewImage12;
      }
      if (imageIndex == 13) {
        this.showNewImage13 = !this.showNewImage13;
      }
      if (imageIndex == 14) {
        this.showNewImage14 = !this.showNewImage14;
      }
      if (imageIndex == 15) {
        this.showNewImage15 = !this.showNewImage15;
      }
    },
    gotoTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    submitFn() {
      if (this.formValues.mailName === "" || this.formValues.remark === "") {
        message.info("必填项不能为空");
        return;
      }
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
        message.success("ส่งอีเมลเรียบร้อยแล้ว เราจะตอบกลับทันที");
        this.formValues = {
          mailName: "",
          email: "",
          remark: "",
        };
      }, 2000);
    },
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: white;
}

img {
  display: block;
}

.imgBox1,
.imgBox2,
.imgBox3,
.imgBox4,
.imgBox5,
.imgBox6,
.imgBox7,
.imgBox8,
.imgBox9,
.imgBox10,
.imgBox11,
.imgBox12,
.imgBox13,
.imgBox14,
.imgBox15 {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #ffffff;
}

img {
  display: block;
}
.img1,
.img2,
.img3,
.img4,
.img5,
.img6,
.img7,
.img8,
.img9,
.img10,
.img11,
.img12,
.img13,
.img14,
.img15 {
  width: 100%;
  overflow: hidden;
  justify-content: space-between;
  margin: 0;
}
.imgItem1,
.imgItem2,
.imgItem3,
.imgItem4,
.imgItem5,
.imgItem6,
.imgItem7,
.imgItem8,
.imgItem9,
.imgItem10,
.imgItem11,
.imgItem12,
.imgItem13,
.imgItem14,
.imgItem15 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
.img1,
.img3,
.img4,
.img5,
.img6,
.img7,
.img8,
.img9,
.img10,
.img11,
.img12,
.img13,
.img14,
.img15 {
  margin-top: 45px;
  /* transform: scale(80%); */
  padding: 0 220px;
}
.img14 {
  margin-bottom: 300px;
}
.img3 {
  margin-top: 70px;
}
.img2 {
  margin-top: 50px;
  /* padding: 0 200px; */
}



.msg2 {
  /* border: 1px solid red; */
  display: flex;
  flex-wrap: wrap;
  /* width: 502px; */
  padding: 20px;
  gap: 10%;
  background-color: #F5F9F9;
}
.msg2 li {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.msg2 li:nth-child(1) {
  width: 30%;
}
.msg2 li:nth-child(2) {
  width: 60%;
}
.msg2 li:nth-child(3) {
  width: 100%;
}

.msg2 li span {
  color: #333333;
  font-family: "Thonburi";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  /* opacity: 0.6; */
  padding-bottom: 10px;
}
.msg2 li input,
textarea {
  all: unset; /* 移除所有默认样式 */
  border: none; /* 去除边框 */
  outline: none; /* 去除聚焦时的轮廓 */
  background: none; /* 去除背景 */
  color: inherit; /* 继承父元素的文本颜色 */
  font: inherit; /* 继承父元素的字体 */
  /* border: 1px solid red; */
  height: 30px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #ffffff;
  padding: 10px;
}

.msg2 li a {
  width: 200px;
  height: 50px;
  line-height: 50px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #8a5d3c;
  color: #ffffff;
  text-align: center;
  font-family: "Thonburi";
  font-size: 20px;
  font-weight: 700;
}

.form {
  display: flex;
  flex-direction: column;
}

.inputBox {
  /* font-size: 18px;
  border: 2px solid #cdcdcd;
  border-radius: 10px;
  height: 50px; */
}

.inputBox >>> .ant-input {
  font-size: 18px;
  border: 2px solid #ffffff;
  border-radius: 10px;
  height: 56px;
  background-color: #ffffff;
}

/* .inputBox>>>.ant-input:focus {
  border: none;
  border-bottom: 1px solid white !important;
  box-shadow: 0 0 1px 2px #4263FF;
}

.inputBox>>>.ant-input:hover  {
  border: none;
  border-bottom: 1px solid white !important;
  box-shadow: 0 0 1px 2px #4263FF;
} */

.textareaBox {
  margin-top: 20px;
  border: 2px solid #ffffff;
  border-radius: 10px;
  font-size: 18px;
}
.textareaBox .ant-input {
  height: 200px;
}

.submitButton {
  background-color: #00BFBF !important;
  color: #ffffff;
  border: none;
  height: 40px;
  border-radius: 5px;
  margin-top: 20px;
  width: 120px;
  font-size: 18px;
}

.btnBgcolor {
  background-color: #00BFBF !important;
  color: #ffffff;
}

/* .submitButton:hover {
  background-color: #ffffff !important;
  color: #001C80;
} */

.inp {
  display: flex;
}
</style>
